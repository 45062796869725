import { Link } from 'gatsby';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb/components';
import React from 'react'
import styled from 'styled-components';
import ContactBanner from '../components/Banner/ContactBanner';
import Hero from '../components/Hero'
import SEO from '../components/SEO'
import { BreadcrumbWrapper, H1, H3Box } from '../styles/common/Element';
import { Col, Container, Row } from '../styles/common/Layout';
import mq from '../styles/media-queries';

const ColMinHeight = styled(Col)`
  padding-top: 3rem;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  h1, p {
    text-align: center;
  }

`

export default function FourOhFourPage({ data }) {

  return (
    <>
      <SEO
        title={'404'}
      />
      <Hero slug={'404'} small>
        <h1 className="headline">404 - Seite nicht gefunden!</h1>
      </Hero>
      <Container as="section">
        <Row className="f-direction-col">
          <ColMinHeight>
            <H1>Webseite leider nicht gefunden! :(</H1>
            <p>Sie sind einem Link gefolgt, der anscheinend kaputt ist, oder die Seite wurde von uns entfernt.</p>
            <p><Link to="/">Klicken Sie hier um, auf die Startseite zurückzukehren</Link></p>
          </ColMinHeight>
        </Row>
      </Container>
      <ContactBanner />
    </>
  )
}
